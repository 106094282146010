<template>
    <div :style="cardHeight" class="text-center d-flex flex-row">
        <div class="flex-fill card mr-3 mb-0 d-flex">
            <highcharts class="mt-4 mr-1 h-100 d-flex flex-column flex-fill" :options="bootingChartOptions"></highcharts>
        </div>
        <div class="text-left">
            <div>
                <button type="button" class="btn btn-primary btn-block mb-2"
                        @clock="refreshData">
                    <i class="fas fa-sync-alt"></i> Refresh
                </button>
            </div>
            <table  class="table table-bordered">
                <tbody>
                    <tr>
                        <th class="text-nowrap">Total boot times</th>
                        <td>{{statisticMachineBootHistory.totalBootTime}} {{statisticMachineBootHistory.totalBootTime > 1 ? 'times':'time'}}</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap">Boot success</th>
                        <td>{{statisticMachineBootHistory.bootSuccessTime}} {{statisticMachineBootHistory.totalBootTime > 1 ? 'times':'time'}}</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap">Fastest boot time</th>
                        <td>{{statisticMachineBootHistory.minDurationBoot | secondDuration}}</td>
                    </tr>
                    <tr>
                        <th class="text-nowrap">Longest boot time</th>
                        <td>{{statisticMachineBootHistory.maxDurationBoot | secondDuration}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <img src="/img/no-data-5.jpg" style="height: 10rem;">
            </div>
        </div>
    </div>
</template>

<script>    
    import commonAct from '@/commonActionHandle.js';
    import vmMachineApi from '@/api/gpuHub/vmMachines';
    import { Chart } from 'highcharts-vue';
    import Highcharts from 'highcharts'
    import { bootSpline } from "@/scripts/gpuHub/vmMachines/bootingChartOptions";
    import moment from 'moment'
    var momentDurationFormatSetup = require("moment-duration-format");
    momentDurationFormatSetup(moment);
    import {  vmMachineStatus } from "@/constant/vmMachineStatusConst";
    const colorSuccess = '#0BEA74';
    const colorDanger = '#F00';
    const colorNormal = '#000';
    export default {
        components: {
            highcharts: Chart
        },
        props: {
            cardHeight: {
                type: String,
                default: 'height: calc(100dvh - 18.5rem);'
            },
            machineItem: {
                type: Object,
                default: null
            },
        },
        watch: {
            machineItem(newVal) {
                if (this.machineItem !== null) {
                     this.getMachineBootHistoryData();
                }
            }
        },
        data() {
            return {
                intervalUpdateChart : null,
                bootingChartOptions: bootSpline,
                vmMachineStatus: vmMachineStatus,
                statisticMachineBootHistory: {},
            }
        },
        mounted() {
            this.getMachineBootHistoryData();
            this.getStatisticMachineBootHistoryData();
            this.intervalUpdateChart = setInterval(() => {
                this.getMachineBootHistoryData();
                this.getStatisticMachineBootHistoryData();
            }, 60000);
        },
        beforeDestroy() {
            clearInterval(this.intervalUpdateChart);
        },
        methods: {
            refreshData() {
                this.getMachineBootHistoryData();
                this.getStatisticMachineBootHistoryData();
            },
            getMachineBootHistoryData() {
                vmMachineApi.getMachineBootHistoryData(this.machineItem.vmMachineId).then(response => {
                    if (response.data && response.data.result === 0) {
                        let dataChart = response.data.data.map(x => {
                            return {
                                y: Math.round((moment.utc(x.timeStamp).diff(moment.utc(x.prevTimeStamp), 'minutes', true) + Number.EPSILON) * 100) / 100,
                                marker: {
                                    fillColor: (x.machineStatus === 2) ? colorSuccess : colorDanger
                                }
                            };
                        });
                        this.bootingChartOptions.series[0].data = [];
                        if (dataChart.length === 1) {
                            this.bootingChartOptions.series[0].data.push({
                                y: 0,
                                marker: {
                                    fillColor: colorNormal
                                }
                            });
                        }
                        this.bootingChartOptions.series[0].data = this.bootingChartOptions.series[0].data.concat(dataChart);
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);

                });
            },
            getStatisticMachineBootHistoryData() {
                vmMachineApi.getStatisticMachineBootHistoryData(this.machineItem.vmMachineId).then(response => {
                    if (response.data && response.data.result === 0) {
                        this.statisticMachineBootHistory = response.data.data;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);

                });
            }
        }
    }
</script>