import Highcharts from 'highcharts'
import moment from "moment";
const colorSun = '#f00';
const colorSat = '#fd7e14';
const colorNormal = '#000';
const bootSpline = {
	chart: {
		type: 'areaspline',
		zoomType: 'x',
		panning: true,
		panKey: 'shift',
		scrollablePlotArea: {
			minWidth: 100
		},
		marginRight: 30,
		animation: Highcharts.svg
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	yAxis: {
		title: {
			text: '',
		},
		labels: {
			formatter: function () {
				return Highcharts.numberFormat(this.value, 0) + (this.value > 1 ? ' minutes' : ' minute');
			}
		}
	},
	xAxis: {
		categories: [],
		title: {
			text: '',
		},
		allowDecimals: false,
		tickInterval: 1,
		labels: {
			enabled: false,
			formatter: function () {
				let color = (moment.utc(this.value, 'DD-MM-YYYY').day() === 0) ? colorSun : ((moment.utc(this.value, 'DD-MM-YYYY').day() === 6) ? colorSat : colorNormal)
				return `<span style="fill: ${color};">${this.value}</span>`;
			}
		}
	},
	legend: { enabled: false },
	plotOptions: {
		areaspline: {
			dataLabels: {
				enabled: true
			},
			fillColor: {
				linearGradient: {
					x1: 0,
					y1: 0,
					x2: 0,
					y2: 1
				},
				stops: [
					[0, Highcharts.getOptions().colors[3]],
					[1, Highcharts.Color(Highcharts.getOptions().colors[8]).setOpacity(0.2).get('rgba')]
				]
			},
			marker: {
				fillColor: Highcharts.getOptions().colors[1],
				lineColor: Highcharts.getOptions().colors[1],
				enabled: true
			},
			lineColor: '#303030'
		},
		series: {
			tooltip: {
				headerFormat: '<b>{series.name}</b><br>',
				pointFormat: 'Boot take {point.y} minutes',
				clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			},
		},
		line: {
			dataLabels: {
				enabled: true
			},
			enableMouseTracking: false,
			marker: {
				fillColor: Highcharts.getOptions().colors[2],
				lineColor: Highcharts.getOptions().colors[2],
				enabled: false
			}
		}
	},
	series: [{
		name: 'Boot duration',
		data: []
	}]
}

export { bootSpline }